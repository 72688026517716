body {
  overflow: visible;
}

body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.hype-scroller {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb-color, transparent) transparent;
}

.hype-scroller:not(.scrolled)::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color, transparent);
  border-radius: 10px;
  transition: transform 0.3s;
}

.hype-scroller.scrolled::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color, transparent);
  border-radius: 10px;
  transition: transform 0.3s;
}

.hype-scroller:hover:not(.scrolled)::-webkit-scrollbar-thumb {
  transform: scale(1.2);
}

.hype-scroller::-webkit-scrollbar {
  width: 10.69px;
  border-radius: 10px;
}

.hype-scroller::-webkit-scrollbar-track {
  background: transparent;
}

.hype-scroller.thumb-hovered::-webkit-scrollbar-thumb {
  transition: background 0.5s;
}

.hype-scroller::-webkit-scrollbar-thumb.end-scroll {
  background: var(--scrollbar-thumb-color-end, transparent);
}
