/* Hype Fade Transition */
.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 327ms ease-in;
}










/* Hype Burn Transition */
.hype-burn-transition {
  position : absolute;
  top : 0;
  left : 0;
  width : 100%;
  min-height : 100vh;
  overflow : hidden;
  height : 100%;
  z-index : 9999;
  opacity : 0;
  transition : opacity 0.5s ease-out;
}

.hype-burn-transition.visible {
  opacity : 1;
}

.hype-burn-transition.hidden {
  visibility : hidden;
  opacity : 0;
}

.hype-burn-overlay {
  position : absolute;
  top : 0;
  left : 0;
  width : 100%;
  height : 100%;
  animation : hypeBurnAnimation 2s ease-out forwards;
}

@keyframes hypeBurnAnimation {
  0% {
    transform : scale(1);
    opacity : 1;
  }
  
  100% {
    transform : scale(2);
    opacity : 0;
  }
}